import React from "react";

const HeaderMenu = () => {
  return (
    <div className="h-center flex aic">
      <a href="https://cryptocolorcollection.com/" style={{ textDecoration:"none" }}><div className="c-item">Home</div></a>
      <a href="https://cryptocolorcollection.com/" style={{ textDecoration:"none" }}><div className="c-item">Roadmap</div></a>
      <a href="https://cryptocolorcollection.com/" style={{ textDecoration:"none" }}><div className="c-item">About</div></a>
      <a href="https://cryptocolorcollection.com/" style={{ textDecoration:"none" }}><div className="c-item">Faqs</div></a>
    </div>
  );
};

export default HeaderMenu;
