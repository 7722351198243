import React from "react";

function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <g clipPath="url(#clip0_18_26)">
        <path
          fill="#6C6C6C"
          d="M13.683 1.688h2.482l-5.421 6.194 6.377 8.43H12.13l-3.913-5.111-4.472 5.111H1.26l5.797-6.626L.942 1.688h5.12L9.593 6.36l4.089-4.673zm-.872 13.14h1.375L5.312 3.095H3.836l8.975 11.735z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_18_26">
          <path fill="#fff" d="M0 0H18V18H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default TwitterIcon;
