import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useColor } from "react-color-palette";
import Color from "./Color";
import "./Home.css";
import "./home-style.scss";
import { SketchPicker } from "react-color";
import Web3 from "web3";
import html2canvas from "html2canvas";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { ContractAddress, abi } from "../config";
import { minted_colors_v1 } from "../minted_color";
import { useAccount, useDisconnect } from "wagmi";
import { ethers } from "ethers";
import { Buffer } from "buffer";
import axios from "axios";
import {
  useContractReads,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { useNetwork, useSwitchNetwork } from "wagmi";

const projectId = "24THgNQOzFCOa5IPMccdboQJybN";
const projectSecret = "49c0eb46af3b0fe34d451b0f19947684";
const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");
const client = ipfsHttpClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});
function Home() {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [color, setColor] = useColor("hex", "#11212");
  const [currentColor, setCurrentColor] = useState("#000000");
  const [description, set_Description] = useState("");
  const [Name1, set_Name] = useState("");
  const { address, isConnected } = useAccount();
  const [imgSrc, setImageSrc] = useState("");
  const [currPrice, set_currPrice] = useState("");
  const [pricewithprint, set_pricewithprint] = useState("");

  const [url, set_url] = useState("");

  const [balance, setBalance] = useState("0");
  const [minted_colors, set_mintedColors] = useState([]);
  const [owner, set_owner] = useState();
  const [isChecked, setIsChecked] = useState(false);
  // let url2;
  const [count, setCount] = useState(0);

  // let count=0;

  // const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({
    userName: "",
    userAddress: "",
    color: currentColor,
  });

  const divRef = useRef(null);
  // const nameRef = useRef(null);
  // const desc_Ref = useRef(null);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    test(!isChecked);
  };

  // Handler function to update the color state when the color changes
  const handleColorChange = (color) => {
    setCurrentColor(color.hex);
  };
  const handle_url = (url) => {
    set_url(url);
  };

  const handle_price = (url) => {
    if (owner.toString().toLowerCase() != address.toString().toLowerCase()) {
      let price = "6000000000000000000";

      if (isChecked) {
        price = "30000000000000000000";
      }
      set_currPrice(price);
      console.log("hello1 dsvsd sdvsdzxc qfwqewqqwd");
    } else {
      set_currPrice("0");
    }
  };

  const { chain } = useNetwork();

  // const { config:claimRewardConfig } = usePrepareContractWrite({
  // address: ContractAddress,
  // abi: abi,
  // functionName: 'createToken',
  // args: [url,currentColor,isChecked],
  // value:[currPrice]

  // })
  const {
    data: stakeResult_withdrawReward,
    isLoading2_withdrawReward,
    isSuccess2_withdrawReward,
    write: withdrawReward,
  } = useContractWrite({
    address: ContractAddress,
    abi: abi,
    functionName: "createToken",
    args: [url, currentColor, isChecked],
    value: [currPrice],
    gas: 300000,
  });

  const networkId = 137;

  const waitForTransaction4 = useWaitForTransaction({
    hash: stakeResult_withdrawReward?.hash,
    onSuccess(data) {
      if (isChecked) {
        test?.(isChecked);
      } else {
        send_mail?.();
        navigate(`/success/${currentColor.slice(1)}`);

      }
      console.log("Success2", data);
    },
  });

  const {
    chains,
    error,
    isLoading,
    pendingChainId,
    switchNetwork: reward_switch,
  } = useSwitchNetwork({
    chainId: networkId,
    // throwForSwitchChainNotSupported: true,
    onSuccess() {
      withdrawReward?.();
    },
  });

  async function test(check) {
    const web3 = new Web3(
      new Web3.providers.HttpProvider("https://polygon-bor.publicnode.com")
    );
    let balance;
    try {
      balance = await web3.eth.getBalance(address);
    } catch (e) {}

    const contract = new web3.eth.Contract(abi, ContractAddress);
    let ret = await contract.methods.listOfColors().call();
    let owner = await contract.methods.owner().call();
    let pricewithprint = await contract.methods.mintWithPrint_price().call();
    let pricewithoutprint = await contract.methods.mint_price().call();

    if (owner.toString().toLowerCase() != address.toString().toLowerCase()) {
      let price = pricewithoutprint;

      if (check) {
        price = pricewithprint;
      }
      set_currPrice(price);

    } else {
      set_currPrice("0");
    }
set_pricewithprint(pricewithprint)
    set_owner(owner);
    setBalance(balance);
    set_mintedColors(ret);
  }

  function check_color(_col) {
    let val = false;
    for (let i = 0; i < 2811; i++) {
      if (minted_colors_v1[i] == _col) {
        console.log(minted_colors_v1[i]);
        val = true;
        break;
      }
    }
    return val;
  }

  async function convert(elements, userName, colorName, Description, formData) {
            // navigate(`/success/${colorName.slice(1)}`);

    if (!isConnected) {
      alert("kindly connect your Wallet");
      return;
    }

    const web3 = new Web3(
      new Web3.providers.HttpProvider("https://polygon-bor.publicnode.com")
    );

    const contract = new web3.eth.Contract(abi, ContractAddress);
    let ret = await contract.methods.colors(colorName).call();

    if (ret) {
      alert("this color has been already minted");
      return;
    }

    html2canvas(elements, {
      height: 500,
      width: 400,
      scale: 3,
    }).then(async function (canvas) {
      console.log(canvas);
      let context = canvas.getContext("2d");
      const data1 = canvas.toDataURL();

      var img = new Image();
      img.src = data1;

      const hello = setImageSrc(img.src);

      const description = Description.toString();

      let name = colorName;

      // if (userName == "") {
      //   alert("Kindly Give a name To your NFT");
      //   return;
      // }
      // if (Description == "") {
      //   alert("Kindly Describe about the NFT");
      //   return;
      // }
      if (userName !== "") {
        name = colorName + "-" + userName;
      }

      if (check_color(colorName) == true) {
        alert(
          "this color is minted in previous collection, you can buy it from https://opensea.io/collection/crypto-color-collection/"
        );
        return;
      }

      const data = JSON.stringify({
        name,
        description,
        image: data1,
      });
      try {
        const added = await client.add(data);
        const url2 = `https://ipfs.infura.io/ipfs/${added.path}`;

        console.log(url2);
        // set_url(url2)
        // if (owner.toString().toLowerCase() != address.toString().toLowerCase())
        // {
        //   let price="6000000000000000000";

        //   if (isChecked)
        //   {
        //     price="30000000000000000000";
        //   }
        //     set_currPrice(price)
        //     console.log("hello1 dsvsd sdvsdzxc qfwqewqqwd");

        // }
        // else{
        //   set_currPrice("0")

        // }
        // createNFT(url2, colorName,formData);
        handle_url(url2);
        // navigate(`/success/${colorName.slice(1)}`);
      } catch (error) {
        console.log(`Error uploading file: `, error);
      }

      console.log("hello1f evaeds");
    });
  }

  async function createNFT(url1, colorName, formData) {
    if (owner.toString().toLowerCase() != address.toString().toLowerCase()) {
      // alert(currPrice)

      if (Number(balance) < Number(currPrice)) {
        alert("You dont have enough funds to buy ");
        return;
      }
    }
    // alert(url)

    // alert(url2)

    if (chain.id != networkId) {
      reward_switch?.();
    } else {
      // handle_url(url1)
      withdrawReward?.();
    }
  }

  useEffect(() => {
    if (address != undefined) {
      test(isChecked);
    }
  }, [address]);

  useEffect(() => {
    if (address != undefined && count != 0) {
      createNFT();
    }
    setCount(count + 1);
  }, [url]);

  async function send_mail() {
    const response = await axios.post(
      "https://ucanglobal-be.vercel.app/api/sendMail",
      formData
    );
    console.log("Response:...", response);
  }

  return (
    <>
      <div
        className={`home-page flex flex-col items-center justify-center relative`}
      >
        <div className="bg-overlay absolute inset-0">
          <video
            autoPlay
            className={`bg-video h-full w-full object-cover`}
            muted
            title="Video Preview"
            src="./images/bg.mp4"
            loop
          />
        </div>
        <div className="page_block flex items-center justify-center inset-0 absolute z-[1]"></div>
        <div className="wrapper wrapperWidth flex items-start gap-10 z-10">
          <div className={`left-box flex flex-[0.8] aic flex-col gap-3`}>
            <button
              className="btn-show flex items-center justify-center"
              onClick={(e) => setShow(!show)}
            >
              {show ? "Hide Color Picker" : "Show Color Picker"}
            </button>
            <div
              className={`color-piker-box flex flex-col ${show ? "show" : ""}`}
            >
              <div className="label font">What is your favorite color?</div>
              {/* <Color color={color} setColor={setColor} /> */}
              <SketchPicker color={currentColor} onChange={handleColorChange} />
            </div>
          </div>

          <div className="center-box flex flex-1 items-center justify-center">
            <div className="color-preview-box flex flex-col" ref={divRef}>
              <div
                className="boxinbox"
                style={{ backgroundColor: currentColor }}
              />
              <div className="boxtext text-end my-2">{currentColor}</div>
              <div className="boxmaintext text-center">
                crypto color collection
              </div>
            </div>
          </div>

          <div className="right-box flex flex-1 aic jc">
            <div className="meta-r flex flex-col w-full gap-3 w-4/5">
              <div className="input-field flex flex-col gap-2">
                <div className="field-lbl font-semibold text-lg text-white">
                  Enter Name (Optional)
                </div>
                <input
                  type="text"
                  className="field-txt text-white font-medium text-base outline-none bg-transparent"
                  placeholder="Type a name for your color"
                  name="colorName"
                  value={Name1}
                  onChange={(e) => set_Name(e.target.value)}
                />
              </div>
              <div
                className="input-r flex flex-col gap-2"
                style={{ border: "1 solid white" }}
              >
                <div className="field-lbl font-semibold text-lg text-white">
                  Description (Optional)
                </div>
                <textarea
                  style={{ height: 70, resize: "none" }}
                  type="text"
                  className="text-area form__field1 field-txt text-white font-medium text-base bg-transparent"
                  placeholder="Write Description"
                  name="colorName"
                  height="30"
                  value={description}
                  onChange={(e) => set_Description(e.target.value)}
                  // ref={desc_Ref}
                />
              </div>
              <div className="input-r flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  className="h-5 w-5 check-box"
                />
                <div className="field-lbl font-normal text-lg text-white">
                  Want a printed version ? (Price : {pricewithprint/10**18} MATIC)
                </div>
              </div>
              <div className="rightbox">Size : 40x50cm / 16x20</div>

              {isChecked ? (
                <div className="mail-form flex flex-col w-full">
                  <div className="input-field flex flex-col gap-2">
                    <div className="field-lbl font-semibold text-lg text-white">
                      Enter your Name
                    </div>
                    <input
                      type="text"
                      className="field-txt text-white font-medium text-base outline-none bg-transparent"
                      placeholder="Type a name here..."
                      name="userName"
                      value={formData.userName}
                      onChange={(e) => {
                        setFormData({ ...formData, userName: e.target.value });
                      }}
                    />
                  </div>
                  <div className="input-field flex flex-col gap-2">
                    <div className="field-lbl font-semibold text-lg text-white">
                      Enter your address
                    </div>
                    <input
                      type="text"
                      className="field-txt text-white font-medium text-base outline-none bg-transparent"
                      placeholder="Address..."
                      name="userAddress"
                      value={formData.userAddress}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          userAddress: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {/* <div className="mint flex ">Mint</div> */}
              <div
                className="my-btn flex aic jc"
                onClick={() => {
                  convert(
                    divRef.current,
                    Name1,
                    currentColor,
                    description,
                    formData
                  );
                }}
              >
                Mint your color now
              </div>
              <div
                className="rightbox"
                style={{ marginTop: "-50px", paddingLeft: "5px" }}
              >
                <text
                  style={{
                    background: "black",
                    color: "white",
                    padding: "4px",
                    borderRadius: "5px",
                  }}
                >
                  Price : {currPrice/10**18} MATIC{" "}
                </text>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default Home;
