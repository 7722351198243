import React from "react";
import "./index.scss";

import {
  TwitterIcon,
  DiscordIcon,
  FacebookIcon,
  InstagramIcon,
} from "../../assets/Icons";
const Footer = () => {


  const socialList = [
    {
      label: "Twitter",
      Icon: <TwitterIcon />,
      url: "https://twitter.com/Cryptocolorco",
    },
    {
      label: "Discord",
      Icon: <DiscordIcon />,
      url: "https://discord.gg/H9RMsY9A",
    },
    {
      label: "Facebook",
      Icon: <FacebookIcon />,
      url: " https://www.facebook.com/CryptoColorCollection",
    },
    {
      label: "Instagram",
      Icon: <InstagramIcon />,
      url: "https://www.instagram.com/cryptocolorcollection/",
    },
    {
      label: "Reddit",
      Icon: <DiscordIcon />,
      url: " https://www.reddit.com/r/CryptoColorCollection/",
    },
  ];
  return (
    <div className="footer flex">
      <div className="wrapWidth flex flex-col">
        <div className="tag">© Crypto Colour Collection</div>
        <div className="desc">
          The world's first Crypto Colour Collection. What is your favorite
          color?
        </div>
      </div>
      <div className="socials flex items-center justify-center">
            {socialList.map((item, index) => (
              <a
                href={item.url}
                target="_blank"
                className="icon flex items-center justify-center"
              >
                {item.Icon}
              </a>
            ))}
          </div>
    </div>

    
  );
};

export default Footer;
