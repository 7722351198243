import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "./responsive.css";
import Home from "./components/Home";
import SuccessPage from "./components/SuccessPage";
import LandingPage from "./components/LandingPage";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer/Footer";
// import Web3Modal from "web3modal";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { polygon } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
// import { useAccount, useDisconnect } from 'wagmi'

import html2canvas from "html2canvas";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { ContractAddress, abi } from "./config";
import { minted_colors_v1 } from "./minted_color";

import { ethers } from "ethers";
import { Buffer } from "buffer";
const projectId = "24THgNQOzFCOa5IPMccdboQJybN";
const projectSecret = "49c0eb46af3b0fe34d451b0f19947684";
const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");
const client = ipfsHttpClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: auth,
  },
});

function App() {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [imgSrc, setImageSrc] = useState("");
  const [formInput, updateFormInput] = useState({ name: "", description: "" });
  // const { address, isConnecting ,isDisconnected} = useAccount()

  const [_address, setAddress] = useState("0");
  const [balance, setBalance] = useState("0");

  const [minted_colors, set_mintedColors] = useState([]);

  const [isWalletConnected, setisWalletConnected] = useState(false);

  const CHAIN_ID = "137";
  const CHAIN_ID1 = "0x89";

  // async function test() {
  //   if (!window.ethereum) {
  //     alert(
  //       "it looks like that you dont have metamask installed, please install"
  //     );
  //     return;
  //   }

  //   try {
  //     const accounts = await window.ethereum.request({
  //       method: "eth_requestAccounts",
  //     });
  //     const networkId = await window.ethereum.request({
  //       method: "net_version",
  //     });
  //     console.log(
  //       "id from async func is: " + CHAIN_ID + "hellggg " + networkId.toString()
  //     );

  //     if (networkId == CHAIN_ID) {
  //       setisWalletConnected(true);

  //       const web3Modal = new Web3Modal();
  //       const connection = await web3Modal.connect();
  //       const provider = new ethers.providers.Web3Provider(connection);

  //       console.log("its in net" + isWalletConnected);

  //       const signer = provider.getSigner();
  //       let balance = await signer.getBalance() ;
  //       balance= ethers.utils.formatEther(balance,18)
  //       let contract = new ethers.Contract(ContractAddress, abi, signer);

  //       let ret = await contract.listOfColors();
  //       console.log("its in net" + isWalletConnected);
  //       console.log(balance);
  //       setBalance(balance)
  //       set_mintedColors(ret);
  //       console.log("All Minted Colors " + ret);
  //       setAddress(window.ethereum.selectedAddress);

  //     } else {
  //       window.ethereum.request({
  //         method: "wallet_switchEthereumChain",
  //         params: [{ chainId: CHAIN_ID1 }],
  //       });
  //       test();
  //     }
  //   } catch (err) {
  //     alert("Something went wrong.");
  //   }
  // }

  // function check_color(_col) {

  //   let val = false;
  //   for (let i = 0; i < 2811; i++) {
  //     if (minted_colors_v1[i] == _col) {
  //       console.log(minted_colors_v1[i]);
  //       val = true;
  //       break;
  //     }
  //   }
  //   return val;
  // }

  // async function convert(elements, userName, colorName, Description, formData) {
  //   if (!isWalletConnected) {
  //     alert("kindly connect your Wallet");
  //     return;
  //   }

  //   html2canvas(elements, {
  //     height: 500,
  //     width: 400,
  //     scale: 3,
  //   }).then(async function (canvas) {
  //     console.log(canvas);
  //     let context = canvas.getContext("2d");
  //     const data1 = canvas.toDataURL();

  //     var img = new Image();
  //     img.src = data1;

  //     const hello = setImageSrc(img.src);

  //     const description = Description.toString();

  //     let name = colorName;

  //     if (userName == "") {
  //       alert("Kindly Give a name To your NFT");
  //       return;
  //     }
  //     if (Description == "") {
  //       alert("Kindly Describe about the NFT");
  //       return;
  //     }
  //     if (userName !== "") {
  //       name = colorName + "-" + userName;
  //     }

  //     const data = JSON.stringify({
  //       name,
  //       description,
  //       image: data1,
  //     });
  //     try
  //     {
  //       const added = await client.add(data);
  //       const url2 = `https://ipfs.infura.io/ipfs/${added.path}`;

  //       //pass the url to sav eit on Polygon adter it has been uploaded to IPFS
  //       createNFT(url2, colorName,formData);
  //       console.log("hello1 dsvsd sdvsdzxc qfwqewqqwd");

  //     }
  //     catch (error)
  //     {
  //       console.log(`Error uploading file: `, error);
  //     }

  //     console.log("hello1f evaeds");
  //   });
  // }

  // async function createNFT(url, colorName,formData) {
  //   // const web3Modal = new Web3Modal();

  //   // const connection = await web3Modal.connect();

  //   const provider = new ethers.providers.Web3Provider(window.ethereum);

  //   //sign the transaction
  //   const signer = provider.getSigner();
  //   let contract = new ethers.Contract(ContractAddress, abi, signer);
  //   let ret = await contract.colors(colorName);
  //   let owner = await contract.owner();

  //   // check_color(colorName)
  //   if (check_color(colorName) == true)
  //   {
  //     alert("this color is minted in previous collection, you can buy it from  https://opensea.io/collection/crypto-color-collection/"+colorName) ;
  //     return;
  //   }

  //   if (ret != true)
  //   {
  //     let transaction;

  //     let price="6000000000000000000";

  //     if (isChecked)
  //     {
  //       price="30000000000000000000";
  //     }

  //     if (owner.toString().toLowerCase() != _address.toString().toLowerCase())
  //     {
  //       if(Number(balance)<Number(price)/10**18)
  //       {
  //         alert("You dont have enough funds to buy ")
  //         return
  //       }

  //       transaction = await contract.createToken(url, colorName,isChecked, {value: price,gasLimit:"4000000"});
  //       if(transaction )
  //       {
  //         if (isChecked)
  //         {
  //           const response = await axios.post(
  //             "https://ucanglobal-be.vercel.app/api/sendMail",
  //             formData
  //           );
  //           console.log("Response:...", response);
  //         }
  //       }
  //     }
  //     else
  //     {

  //       transaction = await contract.createToken(url, colorName,isChecked, { value: "0" ,gasLimit:"4000000"});
  //     }
  //     let tx = await transaction.wait();
  //   }
  //   else
  //   {
  //     alert("this color has been already minted");
  //     return;
  //   }

  // }

  const chains = [polygon];
  const projectId = "8554ac839139eedd017dab0211e628ec";

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);

  // const { chains, publicClient } = configureChains(
  //   [ polygonMumbai],
  //   [alchemyProvider({ apiKey: 'ZF4BW9pKbwOBedI1qGl0uiQHbNu-ISwi' })],
  // )
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <div className="mainbody">
          {/* <Header openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} /> */}
          <Header
          // test={test}
          // isWalletConnected={isWalletConnected}
          // _address={_address}
          />
          <div className="body-content">
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Home
                    // convert={convert}
                    // isChecked={isChecked}
                    // setIsChecked={setIsChecked}
                    />
                  }
                  exact
                />
                <Route path="/success/:colorName" element={<SuccessPage />} />
              </Routes>
            </BrowserRouter>
          </div>
          <Footer />
        </div>
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
}

export default App;
