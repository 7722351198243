import React, { useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import Confetti from "react-confetti";
import htmlToImage from "html-to-image";
// import { FacebookShareButton } from "react-share";
import { TwitterIcon } from "../assets/Icons";

const SuccessPage = () => {
  const location = useLocation();
  const params = useParams();
  console.log("location...", params.colorName);
  return (
    <div className="success-page flex flex-col items-center justify-center min-h-screen relative">
      <div className="bg-overlay absolute inset-0">
        <video
          autoPlay
          className={`bg-video h-full w-full object-cover`}
          muted
          title="Video Preview"
          src="/images/bg.mp4"
          loop
        />
      </div>
      <div className="page_block flex items-center justify-center inset-0 absolute z-[1]"></div>

      <Confetti
        numberOfPieces={200}
        recycle={false}
        initialVelocityX={5}
        initialVelocityY={10}
        width={window.innerWidth}
        height={window.innerHeight}
      />
      <div className="flex gap-5 flex-col items-center justify-center wrapperWidth z-50">
        <h2 className="text-3xl text-center font-semibold mb-4 text-green-600 mt-20">
          Minting Successful!
        </h2>
        <div className="color-preview-box flex flex-col ">
          <div
            className="boxinbox"
            style={{ backgroundColor: `#${params.colorName}` }}
          />
          <div className="boxtext text-end my-2">{`#${params.colorName}`}</div>
          <div className="boxmaintext text-center">
            crypto color collection
          </div>
        </div>
        <div className="flex flex-col w-[400px] mb-20 p-4">
          <p className="text-white mb-4">
            Congratulations! You have successfully minted the color. Your unique
            color is now part of our collection. <a
                
                href="https://mint.cryptocolorcollection.com/"
              >
                              click here to mint again

                {/* <TwitterIcon /> */}
              </a>
          </p>

          <div className="flex justify-center">
            <div className="flex items-center justify-center gap-3">
              <label className="text-white font-normal text-base">Share it with your friends</label>
              <a
                className="flex items-center justify-center bg-white h-8 w-8 rounded-full cursor-pointer"
                target="_blank" href="https://twitter.com/intent/tweet?text= I Just Minted My Favorite Color @cryptocolorco - Whats Your Favorite Color www.cryptocolorcollection.com"  data-size="large"
              >
                <TwitterIcon />
              </a>
            </div>
            {/* <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
              //   onClick={handleShareImage}
            >
              Share
            </button> */}
          </div>

        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
