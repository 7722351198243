export const minted_colors_v1 =["#00a1a6"
,"#1761ab"
,"#1761ab"
,"#179c49"
,"#17b2da"
,"#17e52c"
,"#00a1d7"
,"#1768a3"
,"#052b38"
,"#1733e7"
,"#173182"
,"#173009"
,"#028a0a"
,"#0013bf"
,"#16f7f5"
,"#00a3ac"
,"#025900"
,"#01a681"
,"#1701d7"
,"#03f8b2"
,"#81D8D0"
,"#17125f"
,"#16f7d7"
,"#03f8b2"
,"#81D8D0"
,"#17125f"
,"#16f7d7"
,"#16fc64"
,"#00a2d9"
,"#16ee69"
,"#172095"
,"#171e25"
,"#028c19"
,"#018491"
,"#01a475"
,"#16ffd9"
,"#028add"
,"#00a2da"
,"#01a565"
,"#00a1d9"
,"#00a3b2"
,"#171209"
,"#16ddf8"
,"#171209"
,"#17190b"
,"#17080c"
,"#00a1c4"
,"#1703fa"
,"#025483"
,"#0049c5"
,"#170d5e"
,"#16ec91"
,"#028c00"
,"#00a2cc"
,"#003e24"
,"#16d99a"
,"#170d3e"
,"#16e315"
,"#16e49b"
,"#1709ca"
,"#00a1d0"
,"#00a1d3"
,"#00a1e1"
,"#00a1c2"
,"#16bf6f"
,"#16cecc"
,"#00cf07"
,"#00a1d6"
,"#06d937"
,"#02e13e"
,"#16d0cb"
,"#16c915"
,"#16c303"
,"#01a912"
,"#028d00"
,"#16d705"
,"#03a529"
,"#00e6bc"
,"#00a1cf"
,"#03cd2c"
,"#00c41c"
,"#01a8a8"
,"#00a1e2"
,"#169b74"
,"#16a5e2"
,"#169ad7"
,"#169ba1"
,"#169999"
,"#0762ed"
,"#16a11f"
,"#168f86"
,"#169914"
,"#169771"
,"#16a4c1"
,"#00a2fa"
,"#168999"
,"#16a104"
,"#336633"
,"#0353fd"
,"#16a736"
,"#1698ee"
,"#16a213"
,"#16870f"
,"#03983a"
,"#00a1bf"
,"#169e55"
,"#168d69"
,"#16a4e5"
,"#00250b"
,"#1694b0"
,"#169eac"
,"#1680f9"
,"#1687e5"
,"#16879c"
,"#168073"
,"#169bd8"
,"#168ea9"
,"#168ca9"
,"#16980b"
,"#00a1bb"
,"#02c6c4"
,"#028a63"
,"#1666a1"
,"#00a0b2"
,"#1663ec"
,"#167b66"
,"#078db5"
,"#166b49"
,"#167b1d"
,"#1651f2"
,"#0296a5"
,"#01009d"
,"#033348"
,"#04c97d"
,"#033148"
,"#00a01f"
,"#0049c6"
,"#00a1fd"
,"#165be5"
,"#166e3b"
,"#032a49"
,"#031313"
,"#166b9a"
,"#16535b"
,"#16572c"
,"#165243"
,"#01a751"
,"#049286"
,"#15cf19"
,"#160156"
,"#15fe7d"
,"#1606f1"
,"#161991"
,"#161277"
,"#02cc36"
,"#16101b"
,"#1612a1"
,"#15fde4"
,"#1646f3"
,"#161718"
,"#05043e"
,"#02544c"
,"#165147"
,"#160f1e"
,"#00a2d1"
,"#160f05"
,"#00a1f3"
,"#1646c0"
,"#00a3b7"
,"#160806"
,"#164519"
,"#00d8b8"
,"#1622ba"
,"#164f23"
,"#0762ec"
,"#16054f"
,"#164284"
,"#00a2c8"
,"#016ec4"
,"#160e0b"
,"#16125d"
,"#031b9d"
,"#14e854"
,"#15afe0"
,"#02c8c4"
,"#151619"
,"#022f4d"
,"#00a2e7"
,"#160d47"
,"#022d16"
,"#0c0f0c"
,"#160b15"
,"#028a6f"
,"#1648a6"
,"#009bd8"
,"#07d8fa"
,"#00a1d2"
,"#00841e"
,"#039772"
,"#0348fe"
,"#01e167"
,"#04e257"
,"#161bc8"
,"#163fdc"
,"#1636ba"
,"#01ada3"
,"#1650fb"
,"#15fe28"
,"#016665"
,"#1605c0"
,"#14dab2"
,"#16348f"
,"#118c39"
,"#1618ca"
,"#05043d"
,"#00a1e3"
,"#1603fb"
,"#160850"
,"#141b6f"
,"#13ac2e"
,"#139331"
,"#163d54"
,"#162a8f"
,"#00a2e3"
,"#163832"
,"#16284e"
,"#160908"
,"#160842"
,"#00a2dc"
,"#152286"
,"#165073"
,"#1640b8"
,"#130ac9"
,"#162538"
,"#02df25"
,"#1517a3"
,"#164852"
,"#1648a0"
,"#163a2e"
,"#160c9e"
,"#163da5"
,"#036958"
,"#163a0b"
,"#0000de"
,"#025485"
,"#00a2b2"
,"#0803c1"
,"#1533e5"
,"#03cf58"
,"#00a2b8"
,"#006178"
,"#151503"
,"#00a2e2"
,"#1591e9"
,"#00a03a"
,"#154975"
,"#151c35"
,"#008a23"
,"#0013be"
,"#15f05a"
,"#00e7bb"
,"#1511a9"
,"#00a2d3"
,"#014c2f"
,"#035401"
,"#151310"
,"#156f6b"
,"#151c7a"
,"#14caa3"
,"#151180"
,"#14d84a"
,"#00a3c2"
,"#14dfc8"
,"#15b8f5"
,"#15a2ee"
,"#148cfd"
,"#153301"
,"#0020b1"
,"#003cd0"
,"#14b7d5"
,"#02d35e"
,"#06ef7b"
,"#073900"
,"#151f6a"
,"#053273"
,"#04e258"
,"#00a2b0"
,"#07b7a3"
,"#00bfcc"
,"#151b0d"
,"#152c77"
,"#1527e2"
,"#149617"
,"#027039"
,"#152de1"
,"#0c00ff"
,"#148c82"
,"#15cefb"
,"#00fc40"
,"#05e48b"
,"#02b19e"
,"#1592f5"
,"#15e18f"
,"#07390c"
,"#00a1bc"
,"#152017"
,"#15d5ac"
,"#0361aa"
,"#149787"
,"#153271"
,"#14f967"
,"#0660ad"
,"#03685b"
,"#CCFFFF"
,"#0333d1"
,"#035a92"
,"#00a2bd"
,"#15f69f"
,"#15710c"
,"#15449c"
,"#02ba49"
,"#15b2e2"
,"#15ae2a"
,"#148830"
,"#13e65b"
,"#150dc1"
,"#055297"
,"#01f6c4"
,"#025449"
,"#15586f"
,"#14abd5"
,"#154a49"
,"#15ad21"
,"#15a2e2"
,"#15aa0e"
,"#14d681"
,"#14d468"
,"#1557b9"
,"#156725"
,"#14de37"
,"#03e9a0"
,"#02b3d2"
,"#14b93f"
,"#028adc"
,"#159279"
,"#02df24"
,"#05c176"
,"#14e0bc"
,"#151904"
,"#150406"
,"#149b39"
,"#028f00"
,"#00a3b6"
,"#063ca9"
,"#15ad00"
,"#05c175"
,"#018363"
,"#0bffff"
,"#105298"
,"#03695c"
,"#14a75c"
,"#15878d"
,"#15cdbe"
,"#15b67b"
,"#02f049"
,"#0161d0"
,"#00a1eb"
,"#154650"
,"#150b12"
,"#002dac"
,"#00a1b0"
,"#1566db"
,"#010d93"
,"#00a2be"
,"#15778d"
,"#147a6b"
,"#14c558"
,"#15198f"
,"#1558d7"
,"#00022c"
,"#00a1a4"
,"#155406"
,"#158aca"
,"#00a1ba"
,"#151c73"
,"#0001a7"
,"#153a43"
,"#0288c2"
,"#1597e5"
,"#1500ae"
,"#158918"
,"#1573e3"
,"#03c6e8"
,"#0286e9"
,"#03334a"
,"#01d6a0"
,"#006177"
,"#14f823"
,"#14fa56"
,"#15120d"
,"#149edb"
,"#148b3f"
,"#1558ed"
,"#150403"
,"#03c8d0"
,"#150646"
,"#0394a8"
,"#1520b1"
,"#100003"
,"#1510ce"
,"#14b9ce"
,"#00a1fa"
,"#0361ac"
,"#003f6a"
,"#152dd3"
,"#159700"
,"#013f3d"
,"#1548fa"
,"#15972f"
,"#15dc65"
,"#15520b"
,"#15132b"
,"#15323b"
,"#1584fa"
,"#1585ae"
,"#00a1b8"
,"#019c22"
,"#FFCC99"
,"#030c7d"
,"#06ef79"
,"#013255"
,"#0031fe"
,"#1421b8"
,"#14a87c"
,"#0ff1df"
,"#03e938"
,"#00a3c2"
,"#15bc65"
,"#0201dc"
,"#003e23"
,"#148335"
,"#15232b"
,"#152278"
,"#150370"
,"#00a03c"
,"#14ea8b"
,"#00a2f3"
,"#02f6da"
,"#0709fd"
,"#03fee5"
,"#00a1dd"
,"#022668"
,"#14a8c7"
,"#00a2e0"
,"#15d85a"
,"#158f38"
,"#151e02"
,"#15210e"
,"#151b3a"
,"#00a3bf"
,"#0104ff"
,"#150917"
,"#150c21"
,"#00a3a0"
,"#030c79"
,"#15ca9a"
,"#00a1b2"
,"#00a2ba"
,"#1501c2"
,"#00a1f2"
,"#155ec6"
,"#03f8b1"
,"#15f496"
,"#132809"
,"#013f42"
,"#14ca9e"
,"#1487a2"
,"#155793"
,"#0709fe"
,"#153759"
,"#15734a"
,"#151da9"
,"#0278fb"
,"#1568f4"
,"#00a1ab"
,"#00a1ac"
,"#01043f"
,"#15aed2"
,"#15c606"
,"#1592af"
,"#159dd3"
,"#13fbc6"
,"#14a758"
,"#1549e8"
,"#00a2b9"
,"#00fc3f"
,"#1531d9"
,"#1485de"
,"#149b6e"
,"#139b4a"
,"#140ca6"
,"#15f326"
,"#138ced"
,"#043561"
,"#150dce"
,"#13c847"
,"#15e6f0"
,"#039700"
,"#15d0e7"
,"#00a02e"
,"#13ca91"
,"#14eb89"
,"#13a6b6"
,"#1524d3"
,"#13ecf9"
,"#1585a2"
,"#0531b0"
,"#032653"
,"#0018c3"
,"#13a8fb"
,"#040e97"
,"#043adc"
,"#15d4e6"
,"#010d8c"
,"#0629d4"
,"#13c4d9"
,"#1559e0"
,"#14c9b8"
,"#004e1d"
,"#159f0d"
,"#154092"
,"#13b566"
,"#330000"
,"#15985d"
,"#0642fe"
,"#13296c"
,"#15065a"
,"#00a3a4"
,"#15f786"
,"#150c95"
,"#01fe8c"
,"#018286"
,"#031ffb"
,"#15b0ae"
,"#13e803"
,"#15b49a"
,"#0000dc"
,"#048cff"
,"#15d385"
,"#071f5d"
,"#02d35d"
,"#15f897"
,"#010d89"
,"#105923"
,"#00618d"
,"#03cf57"
,"#13fb60"
,"#150820"
,"#15dae0"
,"#155a09"
,"#1085c6"
,"#13fc28"
,"#000f65"
,"#1546ac"
,"#011aa0"
,"#03334b"
,"#03dc51"
,"#1423b6"
,"#13c180"
,"#13a9fc"
,"#13c27e"
,"#15ba9e"
,"#13c0f8"
,"#15b640"
,"#15a956"
,"#155a96"
,"#159582"
,"#036959"
,"#144041"
,"#101011"
,"#144040"
,"#144045"
,"#141414"
,"#141514"
,"#151a30"
,"#010d88"
,"#140410"
,"#13e6cf"
,"#150806"
,"#15ef96"
,"#159582"
,"#036959"
,"#101011"
,"#144041"
,"#144040"
,"#144045"
,"#141414"
,"#141514"
,"#010d88"
,"#140410"
,"#151a30"
,"#140410"
,"#13e6cf"
,"#150806"
,"#15ef96"
,"#159a6a"
,"#0333d0"
,"#152403"
,"#14b9a3"
,"#156fc9"
,"#13d938"
,"#022900"
,"#15c94a"
,"#15a9ed"
,"#05acfa"
,"#000001"
,"#153947"
,"#156fd9"
,"#03b525"
,"#156993"
,"#0161d2"
,"#03a1a6"
,"#00a2f7"
,"#022669"
,"#152a94"
,"#00a1f8"
,"#14e605"
,"#05f3f3"
,"#025f14"
,"#13ac2b"
,"#156285"
,"#032655"
,"#154e92"
,"#1578ed"
,"#140da8"
,"#13fc06"
,"#03e937"
,"#13da75"
,"#1502db"
,"#1486cb"
,"#15e981"
,"#0498f0"
,"#130952"
,"#15e9b7"
,"#13893c"
,"#14a93b"
,"#153586"
,"#15b8da"
,"#01fd79"
,"#071f5c"
,"#00a01e"
,"#003202"
,"#00a0a0"
,"#028db5"
,"#14a06c"
,"#012bc9"
,"#14ea89"
,"#015b50"
,"#0400c9"
,"#1395a9"
,"#148d3a"
,"#156c86"
,"#13b642"
,"#028ef7"
,"#155603"
,"#15a0f6"
,"#14a3d6"
,"#150234"
,"#028b6b"
,"#0803c2"
,"#1385a3"
,"#140263"
,"#01a300"
,"#01a3ff"
,"#07ed7f"
,"#00a2ec"
,"#111b11"
,"#15a3e7"
,"#07390b"
,"#03fee4"
,"#13b460"
,"#15668a"
,"#050c3f"
,"#02544d"
,"#158009"
,"#00a3aa"
,"#1538fe"
,"#016666"
,"#032654"
,"#14e823"
,"#07d8f8"
,"#00b724"
,"#147242"
,"#0289ff"
,"#13982b"
,"#152b86"
,"#1558a6"
,"#13a4d9"
,"#13a699"
,"#13c07a"
,"#039279"
,"#0436ff"
,"#005041"
,"#1535c6"
,"#1482d3"
,"#028c3b"
,"#15ad87"
,"#00622f"
,"#153dba"
,"#14d039"
,"#13b7d8"
,"#01f08a"
,"#15462e"
,"#00eb01"
,"#028592"
,"#00c41d"
,"#1409d5"
,"#139505"
,"#0531af"
,"#13a6fc"
,"#150462"
,"#011aa1"
,"#024a1d"
,"#057980"
,"#13bae9"
,"#13b619"
,"#14962d"
,"#15df98"
,"#15a9f0"
,"#1598fd"
,"#1573ce"
,"#01277d"
,"#138606"
,"#02d1e2"
,"#02ec57"
,"#028b46"
,"#0278fc"
,"#153088"
,"#0104ed"
,"#03336b"
,"#15a84c"
,"#15e927"
,"#139d18"
,"#00b0b3"
,"#0186e1"
,"#159663"
,"#14208a"
,"#04d6be"
,"#00a002"
,"#01a3a8"
,"#0629d6"
,"#1568df"
,"#139fa0"
,"#138a2e"
,"#00a1c1"
,"#007fc8"
,"#00fe7f"
,"#15a4c6"
,"#159f3a"
,"#02498c"
,"#03e935"
,"#00eaff"
,"#00d9af"
,"#031ffc"
,"#01a700"
,"#1488bf"
,"#153b70"
,"#151936"
,"#15a3ec"
,"#150327"
,"#150038"
,"#1528b7"
,"#150246"
,"#034d00"
,"#024b65"
,"#150813"
,"#15b970"
,"#043563"
,"#019c30"
,"#13a019"
,"#07b7a2"
,"#00c1f2"
,"#0c1000"
,"#0c100c"
,"#13b9dc"
,"#0436fd"
,"#156d07"
,"#157023"
,"#15408c"
,"#1396a3"
,"#156e84"
,"#01bedf"
,"#00c1f3"
,"#00a1c8"
,"#0161d1"
,"#13ec46"
,"#15067e"
,"#150830"
,"#027f98"
,"#00a2e9"
,"#0360c7"
,"#01bee6"
,"#13e05b"
,"#148c6a"
,"#06bf8e"
,"#14e865"
,"#00a1fd"
,"#150249"
,"#1509d7"
,"#0018c2"
,"#151419"
,"#13b940"
,"#14906b"
,"#03336a"
,"#15be30"
,"#01bee4"
,"#00a2d7"
,"#154a82"
,"#009bd7"
,"#138e6f"
,"#13b6a7"
,"#13d7a9"
,"#01a709"
,"#013254"
,"#159f8c"
,"#1490c3"
,"#14098d"
,"#159580"
,"#019c31"
,"#00a2d6"
,"#14865b"
,"#039278"
,"#0a0000"
,"#01a411"
,"#0f4ff0"
,"#13b278"
,"#13a84c"
,"#003399"
,"#13f98e"
,"#13972a"
,"#14c298"
,"#028a00"
,"#02b3d1"
,"#159389"
,"#144424"
,"#1562eb"
,"#05426e"
,"#13d5e2"
,"#07d8ff"
,"#0065db"
,"#148a92"
,"#00a2b1"
,"#016ec5"
,"#15728e"
,"#120396"
,"#139868"
,"#15894e"
,"#0aa000"
,"#1560a7"
,"#150be9"
,"#01f4e4"
,"#000634"
,"#152694"
,"#010523"
,"#000a1e"
,"#154a06"
,"#15c29e"
,"#00a1cc"
,"#15c2d8"
,"#149b58"
,"#01fe8b"
,"#00ac59"
,"#15684b"
,"#1506ed"
,"#1509e7"
,"#00df22"
,"#00a2de"
,"#14e890"
,"#03dc44"
,"#150792"
,"#139720"
,"#1506bc"
,"#018277"
,"#1502da"
,"#15397d"
,"#15083f"
,"#1563b7"
,"#15c607"
,"#028cff"
,"#1586fc"
,"#031ff6"
,"#028c1d"
,"#1392cb"
,"#152b8c"
,"#0104ee"
,"#00a1f0"
,"#15846b"
,"#150de6"
,"#152b3a"
,"#15e2d3"
,"#152c9d"
,"#13ed62"
,"#13b0e6"
,"#158596"
,"#024bb4"
,"#13ec05"
,"#15c496"
,"#15980f"
,"#15a3f8"
,"#14a690"
,"#15386f"
,"#1532ea"
,"#01e175"
,"#01a61e"
,"#152e8c"
,"#15f803"
,"#061c26"
,"#022aed"
,"#063caa"
,"#031b9e"
,"#13c6e2"
,"#15eb68"
,"#13a2e5"
,"#157a09"
,"#15c8e6"
,"#13b60e"
,"#13b29c"
,"#00a2ee"
,"#138e2a"
,"#1508ed"
,"#158c94"
,"#153805"
,"#154682"
,"#101112"
,"#049494"
,"#100001"
,"#100101"
,"#124356"
,"#01a61d"
,"#15a2c9"
,"#0738ff"
,"#00a043"
,"#13b864"
,"#14963b"
,"#144d81"
,"#1536dc"
,"#138b57"
,"#01f2b0"
,"#156dc2"
,"#148c35"
,"#022d1b"
,"#13ae26"
,"#142689"
,"#13964b"
,"#13f86a"
,"#149e83"
,"#15e673"
,"#1578e3"
,"#000f64"
,"#15dc62"
,"#00a2e8"
,"#139705"
,"#13b6d5"
,"#149682"
,"#1570a8"
,"#156804"
,"#157283"
,"#139a54"
,"#1506ba"
,"#15a0e6"
,"#142a48"
,"#127980"
,"#02496e"
,"#153b6d"
,"#1587d9"
,"#1590ce"
,"#13e08c"
,"#000024"
,"#153b02"
,"#152846"
,"#144edf"
,"#13ae96"
,"#1536cb"
,"#158b9d"
,"#15b678"
,"#15c0d9"
,"#152678"
,"#13cb09"
,"#15d9c0"
,"#00a1c6"
,"#01fd78"
,"#15a3d0"
,"#15d879"
,"#012e11"
,"#00a1ca"
,"#15ca03"
,"#029573"
,"#150b6a"
,"#13d86b"
,"#15c3d8"
,"#017af9"
,"#15b6e3"
,"#140586"
,"#02e13d"
,"#1569db"
,"#033599"
,"#13a2c5"
,"#022d24"
,"#158406"
,"#153da0"
,"#0a0f0f"
,"#13d526"
,"#01a2cc"
,"#01f92d"
,"#0137ff"
,"#153b6c"
,"#01bee0"
,"#159b32"
,"#1395c2"
,"#050c3e"
,"#15320e"
,"#003366"
,"#158403"
,"#028a64"
,"#156ce9"
,"#0436fe"
,"#153769"
,"#012bca"
,"#13e209"
,"#028bb5"
,"#153b8c"
,"#1576a8"
,"#030b5a"
,"#042077"
,"#15e90a"
,"#15c8d9"
,"#13c897"
,"#138792"
,"#15026a"
,"#05426d"
,"#15ec36"
,"#1483a9"
,"#0310ff"
,"#00a1ad"
,"#138726"
,"#14a839"
,"#13ec56"
,"#158c9b"
,"#15e832"
,"#15b93a"
,"#139a0d"
,"#01009c"
,"#00a1ea"
,"#00a2d4"
,"#13a2c6"
,"#13d680"
,"#1433aa"
,"#013b08"
,"#156c8a"
,"#13b258"
,"#13ea69"
,"#157968"
,"#15268b"
,"#13a8b9"
,"#15360b"
,"#00e6bb"
,"#139ae6"
,"#0b0001"
,"#13e096"
,"#00a2db"
,"#0348ff"
,"#1538ca"
,"#1385ca"
,"#00fa16"
,"#111d1d"
,"#024969"
,"#031531"
,"#030b5b"
,"#004e1e"
,"#028c1c"
,"#02cbf9"
,"#13960d"
,"#00a1fc"
,"#13da89"
,"#0360c8"
,"#153e06"
,"#0137fe"
,"#13c8a9"
,"#003e22"
,"#1523c6"
,"#1395b0"
,"#153ac8"
,"#139ac8"
,"#01a530"
,"#02ce15"
,"#00a2f5"
,"#00a1db"
,"#134906"
,"#01a5ae"
,"#15d930"
,"#1582a9"
,"#15a9c6"
,"#135972"
,"#152906"
,"#028b4a"
,"#158a62"
,"#02cff3"
,"#150368"
,"#00a3a9"
,"#156c09"
,"#02dadc"
,"#090000"
,"#002dad"
,"#139582"
,"#156293"
,"#00a3ab"
,"#00b0b4"
,"#1539e6"
,"#00d9ae"
,"#139a05"
,"#00fa17"
,"#028b45"
,"#111211"
,"#02c0c2"
,"#1509c6"
,"#01a5b0"
,"#15398b"
,"#13e968"
,"#139086"
,"#145411"
,"#022d1a"
,"#158a03"
,"#0353fe"
,"#139058"
,"#021991"
,"#03d18a"
,"#142f42"
,"#144bfb"
,"#144ffb"
,"#144c14"
,"#144422"
,"#144f4b"
,"#14412f"
,"#00a2cf"
,"#144d17"
,"#078db6"
,"#03bae4"
,"#144c47"
,"#144c17"
,"#1434f3"
,"#144c77"
,"#01f2af"
,"#028e5b"
,"#14491f"
,"#00a3a5"
,"#1441bc"
,"#05236c"
,"#147454"
,"#147751"
,"#111411"
,"#147373"
,"#1447fe"
,"#144dad"
,"#144d21"
,"#144aca"
,"#144444"
,"#144441"
,"#028787"
,"#14412c"
,"#142c2c"
,"#02e13c"
,"#147733"
,"#147433"
,"#144c21"
,"#03c726"
,"#144187"
,"#142c1c"
,"#1440cc"
,"#147478"
,"#14674f"
,"#1455d4"
,"#146f74"
,"#145fbf"
,"#14767f"
,"#144022"
,"#14446b"
,"#142e1f"
,"#00a2a2"
,"#14413a"
,"#144f25"
,"#146214"
,"#143342"
,"#1455fd"
,"#1446cc"
,"#021990"
,"#144ee8"
,"#14512f"
,"#144642"
,"#146d64"
,"#144fd6"
,"#006189"
,"#000a2e"
,"#031ff5"
,"#1444c8"
,"#111117"
,"#11111d"
,"#11111e"
,"#11111c"
,"#143fd1"
,"#00a2ea"
,"#1464b6"
,"#146126"
,"#14418a"
,"#144288"
,"#14646e"
,"#143ee3"
,"#144f3c"
,"#1447a2"
,"#143e13"
,"#145666"
,"#003f68"
,"#14426f"
,"#137a9f"
,"#1442f9"
,"#144900"
,"#146fd6"
,"#1454f0"
,"#142d1b"
,"#00a2c2"
,"#145461"
,"#142a77"
,"#03d188"
,"#02dd00"
,"#146465"
,"#144eb2"
,"#15bebf"
,"#02c2f6"
,"#1557a5"
,"#147fd2"
,"#02864a"
,"#149b4f"
,"#1599f9"
,"#1378fd"
,"#135484"
,"#13817a"
,"#028bb4"
,"#1376fc"
,"#137585"
,"#00a3b5"
,"#134f69"
,"#00a1d4"
,"#1370be"
,"#00a2c6"
,"#136dcf"
,"#135cf2"
,"#1359a3"
,"#01a706"
,"#1357dc"
,"#00022b"
,"#000a2a"
,"#142a18"
,"#142a29"
,"#01325a"
,"#00a1f4"
,"#136800"
,"#00a3af"
,"#035400"
,"#02ca08"
,"#136625"
,"#1384de"
,"#03b526"
,"#003cd1"
,"#02c74f"
,"#136fc2"
,"#135d72"
,"#137b5a"
,"#137ab0"
,"#02c67e"
,"#00a003"
,"#137d0a"
,"#137ed8"
,"#109cb3"
,"#00a1d5"
,"#136ecb"
,"#13538a"
,"#1369b1"
,"#1357ce"
,"#136b68"
,"#00a2a5"
,"#137d9e"
,"#1363b8"
,"#01a680"
,"#006a36"
,"#136658"
,"#138500"
,"#135ead"
,"#1363a9"
,"#1380f6"
,"#135027"
,"#136af5"
,"#1350cd"
,"#024bb5"
,"#135c2e"
,"#1360cd"
,"#136c07"
,"#1356a4"
,"#1359dc"
,"#00a1b9"
,"#02c8c3"
,"#1369b4"
,"#135850"
,"#1350f8"
,"#13795e"
,"#1356a7"
,"#13786e"
,"#14639f"
,"#136570"
,"#02c9a4"
,"#137e96"
,"#135e6d"
,"#1350b2"
,"#135d6b"
,"#137d58"
,"#000a2c"
,"#1368be"
,"#136a2d"
,"#0244f4"
,"#1384c9"
,"#13578c"
,"#136bc8"
,"#137568"
,"#031ff4"
,"#135a9b"
,"#03c6e9"
,"#02cb4f"
,"#00a2fd"
,"#1369e0"
,"#13806b"
,"#00250a"
,"#135c82"
,"#1352c8"
,"#136092"
,"#138209"
,"#1358d6"
,"#00a1b5"
,"#13509c"
,"#136879"
,"#136a09"
,"#136ac8"
,"#135906"
,"#135a68"
,"#CC9999"
,"#145d5f"
,"#145f24"
,"#14446c"
,"#144a44"
,"#144fa1"
,"#145fff"
,"#146614"
,"#144772"
,"#147442"
,"#1434f1"
,"#14747e"
,"#1471e4"
,"#147193"
,"#13f2ff"
,"#145ddd"
,"#147571"
,"#144ac1"
,"#143744"
,"#144daf"
,"#144674"
,"#0569fb"
,"#147719"
,"#14541d"
,"#06991d"
,"#02dadd"
,"#14343b"
,"#1451d4"
,"#03a1a4"
,"#146717"
,"#1437f4"
,"#14737f"
,"#036515"
,"#00a1c3"
,"#1474f3"
,"#00a1b3"
,"#145ee1"
,"#006a35"
,"#14487f"
,"#07d900"
,"#025901"
,"#022aec"
,"#00a02b"
,"#00a1de"
,"#00a2c7"
,"#00fa22"
,"#00a2d8"
,"#142c2f"
,"#1448f7"
,"#14331d"
,"#00fa20"
,"#145452"
,"#0288c3"
,"#00a1b1"
,"#0013ba"
,"#0000a3"
,"#145fdd"
,"#1454e4"
,"#143ffb"
,"#1440f2"
,"#143a14"
,"#144540"
,"#00a3c0"
,"#057979"
,"#031000"
,"#028593"
,"#02c8ff"
,"#145b5f"
,"#145eef"
,"#1474ab"
,"#14512f"
,"#144492"
,"#144dbe"
,"#1443e4"
,"#145c45"
,"#1434c4"
,"#1433df"
,"#1434df"
,"#145df5"
,"#14742d"
,"#145f1c"
,"#14448c"
,"#031ff7"
,"#142944"
,"#146b61"
,"#00a1b6"
,"#144aa9"
,"#145fe4"
,"#1471ae"
,"#146bb6"
,"#0301b6"
,"#02ca07"
,"#02ce16"
,"#00a1d8"
,"#144c6f"
,"#0e01ee"
,"#143999"
,"#00a1ce"
,"#012e12"
,"#145156"
,"#143336"
,"#143103"
,"#144bd0"
,"#145615"
,"#144346"
,"#142dde"
,"#00a1ed"
,"#00a01b"
,"#144193"
,"#1459f1"
,"#02f04a"
,"#13bbff"
,"#00a1cd"
,"#00a0c4"
,"#1470aa"
,"#142bc4"
,"#144d5b"
,"#146f04"
,"#147552"
,"#142cb4"
,"#CCCCFF"
,"#0301b5"
,"#021c16"
,"#061c25"
,"#0310fe"
,"#142de1"
,"#016c94"
,"#00a3a8"
,"#031314"
,"#1451f9"
,"#00a1d1"
,"#00a2f9"
,"#147231"
,"#02c65d"
,"#00a1cb"
,"#143247"
,"#144be5"
,"#1437b1"
,"#142fce"
,"#143cc7"
,"#00eb00"
,"#010512"
,"#147299"
,"#142917"
,"#145af7"
,"#1449e7"
,"#1477c6"
,"#1447e9"
,"#147226"
,"#1452b4"
,"#1476e7"
,"#00fa15"
,"#145cdf"
,"#145877"
,"#1437fc"
,"#144e20"
,"#1443ab"
,"#00a0f8"
,"#147534"
,"#00a2ed"
,"#14342b"
,"#FF6633"
,"#006633"
,"#1452c4"
,"#FFCC00"
,"#1433ab"
,"#145c42"
,"#143dfc"
,"#00a01a"
,"#00a1e8"
,"#010500"
,"#00a1e9"
,"#146acc"
,"#142d7a"
,"#1433ea"
,"#144ae6"
,"#1445b9"
,"#145fce"
,"#145012"
,"#142d9f"
,"#142aa3"
,"#146ae4"
,"#0000fe"
,"#1459ee"
,"#0201dd"
,"#14319b"
,"#00a1df"
,"#145cc9"
,"#143d7b"
,"#00a2c0"
,"#0aaaa0"
,"#0a0a00"
,"#146dd0"
,"#141618"
,"#00a1c9"
,"#123458"
,"#141405"
,"#00a01d"
,"#145e64"
,"#1473f6"
,"#1464e0"
,"#144a03"
,"#145dab"
,"#6699CC"
,"#993333"
,"#1430a3"
,"#142cae"
,"#00a01c"
,"#0186d2"
,"#021316"
,"#02ca06"
,"#1446e9"
,"#1473be"
,"#00a2af"
,"#1448a5"
,"#1468f7"
,"#004e1b"
,"#14493e"
,"#00a1a3"
,"#1458e4"
,"#0020b2"
,"#143df6"
,"#FF33CC"
,"#1435a3"
,"#01141e"
,"#14570b"
,"#145db2"
,"#142996"
,"#146bf8"
,"#145180"
,"#1439b9"
,"#053274"
,"#000a3b"
,"#142f58"
,"#144063"
,"#14729b"
,"#660033"
,"#00a02d"
,"#000a2b"
,"#145669"
,"#143c48"
,"#143fe9"
,"#143fc9"
,"#143a99"
,"#146340"
,"#1467ac"
,"#00a3be"
,"#143561"
,"#145634"
,"#028bff"
,"#03cbb0"
,"#143c8f"
,"#142e97"
,"#145728"
,"#1450c7"
,"#1470b6"
,"#11111b"
,"#039704"
,"#1476d5"
,"#14569f"
,"#143f90"
,"#00fe80"
,"#00a3bc"
,"#14309f"
,"#143398"
,"#003cd2"
,"#03994d"
,"#00a3b1"
,"#00000e"
,"#142bc9"
,"#00a3bb"
,"#022d23"
,"#01e7f2"
,"#143198"
,"#00001e"
,"#146bc2"
,"#147283"
,"#1428ce"
,"#142ab6"
,"#02864b"
,"#03d0cf"
,"#1429b0"
,"#1478b3"
,"#143b5a"
,"#13783f"
,"#013b07"
,"#018278"
,"#02c750"
,"#00a3b9"
,"#03983a"
,"#023b98"
,"#018418"
,"#142d35"
,"#14532d"
,"#1467e8"
,"#123456"
,"#146be5"
,"#1468b7"
,"#145d23"
,"#146b79"
,"#145ac0"
,"#02cb50"
,"#14387a"
,"#00a3b8"
,"#1462d0"
,"#02c6c6"
,"#14678a"
,"#143e97"
,"#00a3b3"
,"#142b93"
,"#1460b5"
,"#00cf0a"
,"#0258ff"
,"#145ae3"
,"#1439ad"
,"#027f80"
,"#145d82"
,"#024a1e"
,"#02c7e0"
,"#1409b0"
,"#1456d3"
,"#1452a6"
,"#02cbfa"
,"#009be2"
,"#024a1f"
,"#146a92"
,"#051f95"
,"#1436d8"
,"#146d93"
,"#146e93"
,"#1468d9"
,"#1439a5"
,"#038583"
,"#1463b8"
,"#005264"
,"#141908"
,"#142498"
,"#0104b7"
,"#142537"
,"#142375"
,"#141096"
,"#0103bb"
,"#02d1e3"
,"#139014"
,"#138994"
,"#138983"
,"#138939"
,"#138772"
,"#02879f"
,"#136934"
,"#030ffe"
,"#136138"
,"#135272"
,"#022983"
,"#14263f"
,"#14186a"
,"#14087b"
,"#13953f"
,"#13979e"
,"#13932d"
,"#13595f"
,"#13514c"
,"#13516f"
,"#1420db"
,"#1403a4"
,"#1399db"
,"#135d7f"
,"#1403bd"
,"#006187"
,"#13abed"
,"#028985"
,"#1399c4"
,"#13fffa"
,"#1373d9"
,"#1369ff"
,"#1364ef"
,"#1363b7"
,"#1364c3"
,"#039d53"
,"#1355ce"
,"#1353ab"
,"#140f95"
,"#0013bb"
,"#138e71"
,"#101010"
,"#005265"
,"#137fd5"
,"#13ea01"
,"#137deb"
,"#03ca90"
,"#137d67"
,"#00a1ee"
,"#136f4e"
,"#136ded"
,"#136ddc"
,"#136c3b"
,"#136bb7"
,"#03766d"
,"#034d60"
,"#0188c0"
,"#13f46e"
,"#0285c5"
,"#03c86a"
,"#13efa2"
,"#13e994"
,"#13ea88"
,"#13e9e2"
,"#13e1c0"
,"#13da5a"
,"#13d129"
,"#13d320"
,"#029e1f"
,"#13d36b"
,"#13d18c"
,"#025484"
,"#13cfbe"
,"#13cc0a"
,"#0103bd"
,"#13cb23"
,"#13ca23"
,"#13c814"
,"#13c55d"
,"#13c9cd"
,"#13c4c8"
,"#13c2fe"
,"#13bf5d"
,"#13bc21"
,"#142106"
,"#13b83c"
,"#01e816"
,"#02cd00"
,"#13af83"
,"#13a848"
,"#13ad23"
,"#13a937"
,"#13a9cc"
,"#13a716"
,"#13a349"
,"#11111a"
,"#022985"
,"#016c9f"
,"#13a8ab"
,"#0104b6"
,"#140977"
,"#0278fd"
,"#140552"
,"#140254"
,"#139929"
,"#139132"
,"#139301"
,"#03b957"
,"#139159"
,"#138474"
,"#022d25"
,"#024a9c"
,"#137783"
,"#03c39a"
,"#0170f3"
,"#016c9e"
,"#137545"
,"#136668"
,"#136467"
,"#031530"
,"#1401a5"
,"#13944d"
,"#13919d"
,"#13931e"
,"#13700d"
,"#13617f"
,"#13631d"
,"#10798b"
,"#1420d0"
,"#1394dd"
,"#1407f9"
,"#00a0ae"
,"#02ccff"
,"#0000dd"
,"#0f0002"
,"#1403e3"
,"#0b1111"
,"#1402cf"
,"#141ae6"
,"#006185"
,"#141c50"
,"#140fab"
,"#138f48"
,"#140c2c"
,"#038584"
,"#138bbf"
,"#138aaa"
,"#137fdb"
,"#00a1aa"
,"#0283e7"
,"#136f15"
,"#135f01"
,"#135d33"
,"#135cf1"
,"#135c35"
,"#13f16b"
,"#13fdf8"
,"#13fcb7"
,"#13fc04"
,"#0104b5"
,"#13f8b3"
,"#000101"
,"#13f878"
,"#141016"
,"#13f254"
,"#029e1d"
,"#13f015"
,"#00a0a9"
,"#13f3ea"
,"#03c869"
,"#13f6f2"
,"#13eef9"
,"#028eff"
,"#13e9ff"
,"#13e421"
,"#13fcca"
,"#13e10f"
,"#03c139"
,"#00a0d7"
,"#028892"
,"#13d535"
,"#13dacc"
,"#0bbbb1"
,"#13d430"
,"#13d455"
,"#13d440"
,"#13d355"
,"#028628"
,"#036523"
,"#024bac"
,"#03c5c0"
,"#13cc61"
,"#004798"
,"#026144"
,"#13c7bf"
,"#13c7b3"
,"#13c4af"
,"#13bbec"
,"#13bf9b"
,"#10957c"
,"#0296a8"
,"#00a0ed"
,"#03cfaa"
,"#13bdbc"
,"#00a0c6"
,"#13bd7d"
,"#13bbf5"
,"#142827"
,"#026599"
,"#13b919"
,"#022af3"
,"#027f81"
,"#01324c"
,"#0072d0"
,"#00a0be"
,"#00a0cb"
,"#028629"
,"#03c451"
,"#0286ea"
,"#03c2c5"
,"#13b34e"
,"#008a90"
,"#13af1c"
,"#003cc9"
,"#022984"
,"#03c714"
,"#0179b5"
,"#036dbd"
,"#13a353"
,"#13a233"
,"#13f3b4"
,"#0099c6"
,"#13f4d4"
,"#13a03a"
,"#13a1ef"
,"#13a1e4"
,"#140b0c"
,"#024bad"
,"#1401ce"
,"#13ee4b"
,"#080888"
,"#13b013"
,"#028829"
,"#03c523"
,"#03c906"
,"#03b95a"
,"#03c201"
,"#02884e"
,"#03c713"
,"#024bab"
,"#00841c"
,"#03bc8c"
,"#028900"
,"#086839"
,"#0ee11f"
,"#140b02"
,"#13edbb"
,"#050c3d"
,"#140d12"
,"#03c522"
,"#142817"
,"#0170f4"
,"#135bb1"
,"#13a3ca"
,"#13a123"
,"#13c5cc"
,"#13c312"
,"#138f44"
,"#13f3de"
,"#13d4f7"
,"#135552"
,"#13d110"
,"#03cf8e"
,"#03c5bf"
,"#022696"
,"#04e285"
,"#037abb"
,"#03bede"
,"#003201"
,"#022697"
,"#03cf8f"
,"#13d2df"
,"#01554c"
,"#03bc89"
,"#0288fe"
,"#03c69d"
,"#140408"
,"#034d51"
,"#01e7f1"
,"#1407e0"
,"#141108"
,"#021317"
,"#141b3d"
,"#101221"
,"#13b1cb"
,"#03c699"
,"#142371"
,"#0197d9"
,"#02dd01"
,"#03c1ff"
,"#0072d1"
,"#03bfb3"
,"#00a0fa"
,"#0e000f"
,"#0e00e0"
,"#0e000e"
,"#141be0"
,"#13eebf"
,"#13f7e7"
,"#0031ff"
,"#140f09"
,"#0e0000"
,"#13af77"
,"#0e0e0f"
,"#0e0e0e"
,"#140616"
,"#13ddcf"
,"#13d3cd"
,"#14070b"
,"#13e3b3"
,"#140bd1"
,"#0072d2"
,"#141808"
,"#141720"
,"#13a173"
,"#13a7f1"
,"#141cae"
,"#14175d"
,"#0e0e00"
,"#00e7d2"
,"#013248"
,"#1354ff"
,"#137bf1"
,"#13cbcc"
,"#03c7b6"
,"#136ff6"
,"#006186"
,"#03ba2a"
,"#141cba"
,"#027f7f"
,"#03c672"
,"#03c1f9"
,"#1411cb"
,"#13b37b"
,"#0147e5"
,"#03c2c7"
,"#13f38f"
,"#13ecce"
,"#141b2d"
,"#03bd1e"
,"#1424a7"
,"#13d3bb"
,"#13f41c"
,"#13f366"
,"#14226f"
,"#141f05"
,"#036524"
,"#1418c8"
,"#1377d1"
,"#141340"
,"#13777a"
,"#1413fe"
,"#13d731"
,"#03cbaf"
,"#141403"
,"#1417da"
,"#141015"
,"#142264"
,"#142126"
,"#14127d"
,"#00a0d1"
,"#13bbf4"
,"#140f21"
,"#1412d7"
,"#028734"
,"#13b4ff"
,"#0267d1"
,"#14041c"
,"#03c674"
,"#028733"
,"#140b04"
,"#141afe"
,"#13a1a1"
,"#140f17"
,"#028551"
,"#13f1bf"
,"#13df3f"
,"#137774"
,"#1414ea"
,"#00a0c7"
,"#141bba"
,"#13737f"
,"#141757"
,"#13f137"
,"#106539"
,"#01140d"
,"#02844d"
,"#0284d0"
,"#01277c"
,"#14224f"
,"#141e1f"
,"#141ffb"
,"#142121"
,"#142144"
,"#141f4f"
,"#031baf"
,"#00a0ac"
,"#124c63"
,"#0286e6"
,"#12b0a5"
,"#12ac05"
,"#128a94"
,"#130f95"
,"#125cd8"
,"#128be3"
,"#128b0e"
,"#126c84"
,"#125c0b"
,"#0285c3"
,"#102cd8"
,"#10ae32"
,"#127580"
,"#10954a"
,"#02fd6f"
,"#022af4"
,"#020ece"
,"#0284cf"
,"#12e8a5"
,"#00a0d5"
,"#1308bd"
,"#105827"
,"#10756c"
,"#1079e5"
,"#1057c9"
,"#036522"
,"#10e32a"
,"#10ac6d"
,"#119568"
,"#0170f2"
,"#03cf56"
,"#0179b6"
,"#128b5c"
,"#12c90b"
,"#120a3e"
,"#105eb6"
,"#107d39"
,"#12c39a"
,"#10a945"
,"#03cb17"
,"#12c5a0"
,"#10d38b"
,"#10c65b"
,"#01508a"
,"#1206da"
,"#10ac52"
,"#03c98b"
,"#03c2c6"
,"#106428"
,"#12c09b"
,"#012bc8"
,"#13075a"
,"#03c715"
,"#01554b"
,"#120ad3"
,"#1306ba"
,"#10763c"
,"#10b62c"
,"#03c09d"
,"#03314b"
,"#106c79"
,"#1290ad"
,"#031bae"
,"#10a9f8"
,"#03c398"
,"#108ce2"
,"#12d69e"
,"#02dd02"
,"#1257c8"
,"#106e5c"
,"#123b9c"
,"#12c9e0"
,"#103465"
,"#01140c"
,"#020ecd"
,"#12d5e6"
,"#1068db"
,"#128456"
,"#03bdff"
,"#03766f"
,"#0188bf"
,"#12d8c6"
,"#03cfab"
,"#103945"
,"#00001d"
,"#031100"
,"#0b0000"
,"#993399"
,"#10c93d"
,"#1026b5"
,"#12e6a3"
,"#03c452"
,"#008a8f"
,"#1069bd"
,"#002506"
,"#130728"
,"#002507"
,"#105b6a"
,"#03bbce"
,"#00a0b9"
,"#0008d4"
,"#004573"
,"#0267d7"
,"#0287a4"
,"#0289fd"
,"#028893"
,"#0283e1"
,"#132578"
,"#10489a"
,"#12c0a9"
,"#0f0d0d"
,"#034d5f"
,"#1205b9"
,"#10b6a5"
,"#108a49"
,"#1345c8"
,"#10b56a"
,"#10253b"
,"#1320e9"
,"#03c1fa"
,"#022af5"
,"#105489"
,"#130c9b"
,"#108549"
,"#12e503"
,"#12e530"
,"#12a8b6"
,"#10bc83"
,"#108e25"
,"#10a5b8"
,"#12e905"
,"#003200"
,"#002508"
,"#00a0a1"
,"#00001c"
,"#00a1a9"
,"#03c673"
,"#108bc6"
,"#1286c0"
,"#0147e6"
,"#0267d0"
,"#1068da"
,"#10e629"
,"#1283ca"
,"#107358"
,"#10952d"
,"#10a6e9"
,"#12069e"
,"#0337c9"
,"#03bc88"
,"#01277e"
,"#106ad8"
,"#069006"
,"#03cf55"
,"#120b39"
,"#00a0f0"
,"#12650a"
,"#12069b"
,"#129a03"
,"#10a329"
,"#0008d3"
,"#12a960"
,"#00a0d8"
,"#109e25"
,"#03c138"
,"#0298d5"
,"#1092d6"
,"#10c5a9"
,"#1298ac"
,"#10236c"
,"#128d05"
,"#128d50"
,"#12c953"
,"#12a096"
,"#10da68"
,"#0197da"
,"#12809d"
,"#12603e"
,"#12568e"
,"#12385b"
,"#1203a5"
,"#132e58"
,"#10836d"
,"#02062f"
,"#0283e4"
,"#049d56"
,"#000a03"
,"#04a38d"
,"#026145"
,"#056ce9"
,"#0548ad"
,"#053ad7"
,"#00a0fb"
,"#00a0e9"
,"#00a0c3"
,"#03c1f8"
,"#046587"
,"#03f829"
,"#01e823"
,"#008419"
,"#05681d"
,"#04da85"
,"#04a96d"
,"#0573da"
,"#0527e3"
,"#00a0ca"
,"#04a8d5"
,"#0564a2"
,"#04c328"
,"#0563a3"
,"#03edb9"
,"#0532bd"
,"#047683"
,"#05619b"
,"#04c5a6"
,"#053acb"
,"#03ca91"
,"#03bedd"
,"#053249"
,"#04635b"
,"#04c98b"
,"#036dbe"
,"#046798"
,"#00a2e6"
,"#053874"
,"#0463da"
,"#000020"
,"#049a3d"
,"#03bae3"
,"#042a63"
,"#04d8a9"
,"#04b583"
,"#056a81"
,"#0572a8"
,"#0539f6"
,"#049783"
,"#0562ed"
,"#000a2d"
,"#0074fd"
,"#00a0d6"
,"#03c1fe"
,"#03f568"
,"#0519c6"
,"#03c69c"
,"#03dcfb"
,"#046a3c"
,"#02659c"
,"#042c96"
,"#051689"
,"#04a6c8"
,"#00a2f2"
,"#03dcfc"
,"#042386"
,"#053792"
,"#056342"
,"#03766e"
,"#042389"
,"#05736d"
,"#05783b"
,"#03bfb2"
,"#05679e"
,"#04e689"
,"#04869e"
,"#00a0ec"
,"#049832"
,"#04986e"
,"#046b38"
,"#0458e3"
,"#0536e9"
,"#000017"
,"#000a3a"
,"#000011"
,"#04a953"
,"#052763"
,"#052de8"
,"#052ad6"
,"#056728"
,"#057862"
,"#053ba6"
,"#045c83"
,"#056348"
,"#0563bd"
,"#057298"
,"#057829"
,"#056c78"
,"#0562b9"
,"#056278"
,"#042698"
,"#04a583"
,"#057982"
,"#03c13b"
,"#03d652"
,"#00a0bf"
,"#03ac6e"
,"#00a0c5"
,"#0297a8"
,"#0f0100"
,"#02fd71"
,"#03978b"
,"#0263b8"
,"#03c6b9"
,"#0e0001"
,"#0358be"
,"#004572"
,"#024a9d"
,"#00ffff"
,"#ff00ff"
,"#02a9d8"
,"#039587"
,"#035a69"
,"#036ba9"
,"#0296d3"
,"#01f929"
,"#0258e6"
,"#039a65"
,"#0238b5"
,"#02d869"
,"#00a0ff"
,"#0f1f77"
,"#0f1eee"
,"#0f0d0f"
,"#013895"
,"#02f6fe"
,"#00a0f1"
,"#00a0d3"
,"#0f0ccc"
,"#00FF00"
,"#02f6d9"
,"#02062e"
,"#03ca0d"
,"#00a0fe"
,"#03dcfa"
,"#111111"
,"#000021"
,"#02365b"
,"#0000a2"
,"#00a0ee"
,"#ff3333"
,"#015893"
,"#1000d1"
,"#1001e1"
,"#10000c"
,"#100747"
,"#10011e"
,"#1001cc"
,"#10000a"
,"#0ffc1f"
,"#00a0fc"
,"#00a0d9"
,"#0fcfcc"
,"#100f0f"
,"#0f4774"
,"#0f0e0f"
,"#056dc8"
,"#03cf75"
,"#00a0ef"
,"#00a0fd"
,"#0ef00f"
,"#033147"
,"#00000c"
,"#000010"
,"#0e110e"
,"#0dfdf1"
,"#0df11f"
,"#0337c8"
,"#028828"
,"#0cf1ff"
,"#03bdf3"
,"#0ccf11"
,"#0ccc01"
,"#0ee0e1"
,"#0cc1cc"
,"#0ccfcf"
,"#12863a"
,"#12859b"
,"#130e59"
,"#9999CC"
,"#132068"
,"#130a6c"
,"#120369"
,"#126903"
,"#130692"
,"#111112"
,"#1296e3"
,"#0288ff"
,"#0301be"
,"#12859d"
,"#12839c"
,"#12805a"
,"#1280a9"
,"#0000ff"
,"#126580"
,"#1265a9"
,"#12596a"
,"#00a0f9"
,"#126093"
,"#123a69"
,"#00a0f7"
,"#12598d"
,"#125983"
,"#000009"
,"#123980"
,"#010d94"
,"#120859"
,"#10d9a5"
,"#105e89"
,"#1069a3"
,"#1065b3"
,"#00a0f6"
,"#1056d9"
,"#03c200"
,"#105238"
,"#103ca9"
,"#103c9a"
,"#10c98a"
,"#10a9c8"
,"#10953d"
,"#108a6c"
,"#02563a"
,"#10839e"
,"#004799"
,"#00a0d2"
,"#108295"
,"#106d85"
,"#106d39"
,"#00a0bb"
,"#ff99cc"
,"#039b86"
,"#023d58"
,"#03a98c"
,"#0328c6"
,"#02d986"
,"#02d598"
,"#03c7b7"
,"#035d92"
,"#02c893"
,"#0210fb"
,"#02a835"
,"#029e83"
,"#00a0f5"
,"#00a0cd"
,"#0236a8"
,"#108592"
,"#1238a9"
,"#128950"
,"#11111f"
,"#106928"
,"#000007"
,"#100000"
,"#105986"
,"#130896"
,"#00a2a7"
,"#00a0e8"
,"#105693"
,"#12689a"
,"#109638"
,"#111114"
,"#050437"
,"#000008"
,"#129683"
,"#105938"
,"#05468a"
,"#109385"
,"#03ca0e"
,"#056ae8"
,"#0523ca"
,"#051386"
,"#031315"
,"#0385d6"
,"#003cca"
,"#029e1e"
,"#048c69"
,"#0362a5"
,"#0365a2"
,"#02356a"
,"#03a289"
,"#0074fc"
,"#0298a6"
,"#036d85"
,"#1111f7"
,"#0210fc"
,"#035d68"
,"#03658a"
,"#036b85"
,"#00000b"
,"#0385b9"
,"#03856c"
,"#03589a"
,"#03c968"
,"#03d985"
,"#039a86"
,"#03a689"
,"#036a58"
,"#1111ff"
,"#11fff1"
,"#024b66"
,"#114f11"
,"#11f4f1"
,"#00a0cf"
,"#11f17f"
,"#056e8b"
,"#04a839"
,"#0485a6"
,"#021100"
,"#02f700"
,"#013249"
,"#056387"
,"#00a0b8"
,"#056e82"
,"#057238"
,"#057863"
,"#03c397"
,"#03c7b5"
,"#0529e8"
,"#052c8a"
,"#0526d3"
,"#000a1d"
,"#0536ac"
,"#053b28"
,"#053b8c"
,"#00a0af"
,"#0538ac"
,"#056b98"
,"#023c4d"
,"#05398c"
,"#053ac6"
,"#053c6a"
,"#056c38"
,"#001809"
,"#056b38"
,"#0538b6"
,"#100441"
,"#00a0ba"
,"#101440"
,"#0f400f"
,"#04fa3f"
,"#100414"
,"#037abc"
,"#1001f0"
,"#011bb3"
,"#0fffff"
,"#0ffbff"
,"#0ff70f"
,"#0ff41f"
,"#00a0f4"
,"#00a0a6"
,"#0ff0fb"
,"#0fbfff"
,"#02884d"
,"#034d52"
,"#02867e"
,"#015500"
,"#FF3366"
,"#0f4f14"
,"#00a0c1"
,"#00a0f2"
,"#02f6ff"
,"#0f00f1"
,"#0dd0dd"
,"#0d111d"
,"#0a111a"
,"#0000ff"
,"#0a000a"
,"#0a0aff"
,"#0a1111"
,"#0aff1f"
,"#0afff0"
,"#0b1bff"
,"#0b01f1"
,"#0b1f0f"
,"#00a0a8"
,"#0c0c44"
,"#0bb0fb"
,"#0c00c1"
,"#0bbb00"
,"#0bf000"
,"#0bf0ff"
,"#0bff00"
,"#00a0ad"
,"#00a0c2"
,"#0bfff4"
,"#0c0000"
,"#0c0c10"
,"#0c10c0"
,"#0c10c1"
,"#090990"
,"#090909"
,"#009988"
,"#00e06c"
,"#00a0a4"
,"#03aef5"
,"#044ff3"
,"#03e9a3"
,"#04fa04"
,"#06aaf3"
,"#00180d"
,"#05c549"
,"#05eddd"
,"#0804b4"
,"#088505"
,"#084cc4"
,"#089be3"
,"#01cc4b"
,"#03d0d0"
,"#05acfb"
,"#07f97b"
,"#071f5e"
,"#07f97a"
,"#00a1f6"
,"#01a788"
,"#034cff"
,"#0400c8"
,"#045f6d"
,"#04458f"
,"#045719"
,"#051f94"
,"#0566bb"
,"#01c780"
,"#00fc3e"
,"#015087"
,"#0065dc"
,"#02884b"
,"#03359c"
,"#03743a"
,"#037f11"
,"#00aa38"
,"#00a1f5"
,"#00a0ab"
,"#00aa37"
,"#00000d"
,"#02c2f7"
,"#00180c"
,"#0210ff"
,"#03cf76"
,"#000006"
,"#00a0c8"
,"#0301bd"
,"#00841b"
,"#005266"
,"#028984"
,"#028417"
,"#000a1c"
,"#02841a"
,"#01c10b"
,"#03be00"
,"#028aff"
,"#03cb16"
,"#03c90a"
,"#000100"
,"#000003"
,"#02cff4"
,"#0005a8"
,"#03b357"
,"#000a1f"
,"#033392"
,"#00a0b0"
,"#003cb2"
,"#03b559"
,"#006c4b"
,"#003cc6"
,"#999999"
,"#02b500"
,"#000002"
,"#000a3c"
,"#000004"
,"#02c9a5"
,"#000a1b"
,"#02c900"
,"#000a0f"
,"#00a0d4"
,"#00a00d"
,"#00a3a6"
,"#00a2fe"
,"#00a001"
,"#000a02"
,"#00a1e4"
,"#00a1e0"
,"#ffffff"
,"#00a1a5"
,"#000a0c"
,"#000a01"
,"#00a1a8"
,"#00a1a0"
,"#000a1a"
,"#00a1a2"
,"#000a0b"
,"#00a0a2"
,"#00a1a1"
,"#0000a1"
,"#00a00a"
,"#00a0eb"
,"#00a0d0"
,"#00000a"
,"#0000a0"
,"#00a00c"
,"#fffffe"
,"#00a0c9"
,"#000a0a"
,"#00a0bc"
,"#00a00b"
,"#00a0b1"
,"#00a0aa"
,"#00a000"
,"#000a00"
,"#663399"
,"#ffff00"
,"#000000"
,"#028e5b"];